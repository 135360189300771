<template>
  <div class="carousel-example">
    <swiper
      :options="swiperOption"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :key="$vs.rtl"
    >
      <swiper-slide>
        <img
          class="responsive"
          src="@/assets/images/pages/carousel/1.jpg"
          alt="banner"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          class="responsive"
          src="@/assets/images/pages/carousel/2.jpg"
          alt="banner"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          class="responsive"
          src="@/assets/images/pages/carousel/3.jpg"
          alt="banner"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          class="responsive"
          src="@/assets/images/pages/carousel/4.jpg"
          alt="banner"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          class="responsive"
          src="@/assets/images/pages/carousel/5.jpg"
          alt="banner"
        />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <div id="page-user-view">
      <div>
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ClockIcon"
              :statistic="CourseData.duration_name"
              statisticTitle="Duration"
              color="primary"
            />
          </div>
          <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              :statistic="CourseData.tot_fee"
              statisticTitle="Tutions Fees/Year (indicative)"
            />
          </div>

          <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              statisticTitle="Application Fees (indicative)"
              :statistic="CourseData.application_fee"
              color="primary"
            />
          </div>

          <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="CheckSquareIcon"
              :statistic="CourseData.admi_status"
              statisticTitle="Application Status"
              color="success"
            />
          </div>
        </div>

        <vx-card :title="CourseData.course_name" class="mb-base mt-4">
          <template slot="actions">
            <div class="mt-3 md:mt-0 d-flex items-center" >
            <!-- <vs-button
              class="ml-2 mb-mob-15"
              :disabled="CourseData.is_enquire == 1"
              color="primary"
              @click="AddUserEnquiryForCourse"
              >ENQUIRE NOW</vs-button
            > -->
            <!-- <div>
            <feather-icon class="mr-2" icon="HeartIcon" svgClasses="w-6 h-6" :disabled="CourseData.is_shortlist == 1"  @click="AddUserShortlistCourse">
            </feather-icon>
          </div> -->

             <vx-tooltip text="Shortlist">
            <vs-button color="primary" size="small" type="filled" icon-pack="feather" icon="icon-heart" 
            :disabled="CourseData.is_shortlist == 1"  @click="AddUserShortlistCourse"></vs-button>
             </vx-tooltip>

            <vs-button
              class="ml-2"
              size="small"
              color="primary"
              @click="goToChatCoursepal()"
              >MESSAGE</vs-button
            >

            <vs-button
              class="ml-2"
              size="small"
              :disabled="CourseData.is_applied == 1"
              color="primary"
              @click="redirectMyApplication"
              >APPLY NOW</vs-button
            >

            <!-- <vs-button
              class=""
              color="primary"
              size="small"
              :to="'/my-application'"
              >APPLY NOW</vs-button
            > -->

            <!-- <vs-button @click="popupEnquire=true" class="ml-2" size="small" color="primary" type="border">Enquire</vs-button> -->

           

            
            <!-- <vs-button
              class="ml-2"
              :disabled="CourseData.is_shortlist == 1"
              color="primary"
              @click="AddUserShortlistCourse"
              >SHORTLIST</vs-button
            > -->
            </div>
          </template>

          <!-- Avatar -->
          <div class="vx-row">
            <!-- Avatar Col -->

            <!-- Information - Col 1 -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <table v-if="CourseData">
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="DatabaseIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Cricos Code
                  </td>
                  <td>{{ CourseData.course_code }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="HomeIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />University
                  </td>
                  <td class="font-semibold text-primary">
                    {{ CourseData.institution }}
                  </td>
                </tr>
                <tr v-if="CourseData.study_method != 'Online'">
                  <td class="font-semibold">
                    <feather-icon
                      icon="MapIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Campus
                  </td>
                  <td class="font-semibold text-success">
                    {{ CourseData.campus_name }}
                  </td>
                </tr>
                <tr v-if="CourseData.study_method != 'Online'">
                  <td class="font-semibold">
                    <feather-icon
                      icon="MapPinIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Location
                  </td>
                  <td>{{ CourseData.campus_loc_name }}</td>
                </tr>
                <tr v-if="CourseData.study_method == 'Online'">
                  <td class="font-semibold">
                    <feather-icon
                      icon="AirplayIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Link
                  </td>
                  <td
                    class="text-primary cursor-pointer "
                    style="font-size:11px"
                    @click="OpenNewTab(CourseData.exe_link)"
                  >
                    {{ CourseData.exe_link }}
                  </td>
                </tr>
              </table>
            </div>

            <div class="vx-col flex-1" id="account-info-col-2">
              <table>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="BookOpenIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Study Method
                  </td>
                  <td class="font-semibold text-danger">
                    {{ CourseData.study_method }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="BookmarkIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Study Area
                  </td>
                  <td>{{ CourseData.study_area_name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="BookIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Course Type
                  </td>
                  <td>{{ CourseData.course_type_name }}</td>
                </tr>

                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="CalendarIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Intake Months
                  </td>
                  <td>{{ CourseData.month_names }}</td>
                </tr>
              </table>
            </div>
          </div>
        </vx-card>
        <vx-card title="Descriptions" class="mb-base mt-4">
          <!-- Avatar -->
          <div class="vx-col" v-html="CourseData.cou_about">
            {{ CourseData.cou_about }}
          </div>
        </vx-card>
        <vx-card title="University" class="mb-base mt-4">
          <template slot="actions"> </template>

          <!-- Avatar -->
          <div class="vx-row">
            <!-- Avatar Col -->

            <!-- Information - Col 1 -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <table v-if="CourseData">
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="HomeIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Name
                  </td>
                  <td class="font-semibold text-primary">
                    {{ CourseData.institution }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="UsersIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Total Students
                  </td>
                  <td>{{ CourseData.univ_tot_no_of_stu }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="MapIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />World Ranking
                  </td>
                  <td class="font-semibold text-success">
                    {{ CourseData.univ_world_ranking }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="BookmarkIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Website Link
                  </td>
                  <td
                    class="text-primary cursor-pointer"
                    @click="OpenNewTab(CourseData.univ_website_url)"
                  >
                    {{ CourseData.univ_website_url }}
                  </td>
                </tr>
              </table>
            </div>

            <div class="vx-col flex-1" id="account-info-col-2">
              <table>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="DatabaseIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />CRICOS Code
                  </td>
                  <td>{{ CourseData.univ_code }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="BookIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />International Students
                  </td>
                  <td>{{ CourseData.univ_tot_no_of_int_stu }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="BookOpenIcon"
                      svgClasses="w-4 h-4 mr-1 "
                    />Ranking in Australia
                  </td>
                  <td class="font-semibold text-success">
                    {{ CourseData.univ_ranking_in_aus }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="vx-col mt-4">
              <h4>Descriptions</h4>
              <!-- Avatar -->
              <div class="vx-col mt-5" v-html="CourseData.univ_about">
                {{ CourseData.univ_about }}
              </div>
            </div>
          </div>
        </vx-card>
        <div class="vx-row">
          <div
            class="vx-col lg:w-full w-full"
            v-if="CourseData.study_method != 'Online'"
          >
            <vx-card title="Campus Details" class="mb-base">
              <table>
                <tr>
                  <td class="font-semibold">Name</td>
                  <td class="font-semibold text-primary">
                    {{ CourseData.campus_name }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">Location</td>
                  <td>{{ CourseData.campus_loc_name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Address</td>
                  <td>{{ CourseData.cam_address }}</td>
                </tr>
              </table>

              <div class="vx-col mt-4">
                <h4>Descriptions</h4>
                <!-- Avatar -->
                <div class="vx-col mt-5" v-html="CourseData.cam_about">
                  {{ CourseData.cam_about }}
                </div>
              </div>
            </vx-card>
          </div>
        </div>

        <vx-card title="Eligibility">
          <div class="vx-col lg:w-3/4 w-full">
            <table class="mt-1 pt-1">
              <tr>
                <td class="font-semibold ">Work-Experince</td>
                <td class="ml-4">
                  <span class="ml-5" v-if="CourseData.is_exp_req == 'N'"
                    >Not Required</span
                  >
                </td>
                <td class="ml-4">
                  <span class="ml-5" v-if="CourseData.is_exp_req == 'Y'">
                    Required</span
                  >
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Minimum Qualifications</td>
                <td>
                  <span class="ml-5">{{ CourseData.higher_degree_name }} </span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">% of Mark Required</td>
                <td class="ml-4">
                  <span class="ml-5"> {{ CourseData.mark_req }}</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="vx-row">
            <div class="vx-col lg:w-1/3 w-full">
              <vx-card title="IELTS" class="mb-base">
                <table>
                  <tr>
                    <td class="font-semibold">Overall</td>
                    <td>{{ CourseData.ielts_overall }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Reading</td>
                    <td>{{ CourseData.ielts_read }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Writing</td>
                    <td>{{ CourseData.ielts_write }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Listening</td>
                    <td>{{ CourseData.ielts_list }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Speaking</td>
                    <td>{{ CourseData.ielts_speech }}</td>
                  </tr>
                </table>
              </vx-card>
            </div>

            <div class="vx-col lg:w-1/3 w-full">
              <vx-card title="PTE" class="mb-base">
                <table>
                  <tr>
                    <td class="font-semibold">Overall</td>
                    <td>{{ CourseData.pte_overall }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Reading</td>
                    <td>{{ CourseData.pte_read }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Writing</td>
                    <td>{{ CourseData.pte_write }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Listening</td>
                    <td>{{ CourseData.pte_list }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Speaking</td>
                    <td>{{ CourseData.pte_speech }}</td>
                  </tr>
                </table>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/3 w-full">
              <vx-card title="TOEFL" class="mb-base">
                <table>
                  <tr>
                    <td class="font-semibold">Overall</td>
                    <td>{{ CourseData.toefl_overall }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Reading</td>
                    <td>{{ CourseData.toefl_read }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Writing</td>
                    <td>{{ CourseData.toefl_write }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Listening</td>
                    <td>{{ CourseData.toefl_list }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Speaking</td>
                    <td>{{ CourseData.toefl_speech }}</td>
                  </tr>
                </table>
              </vx-card>
            </div>
          </div>
        </vx-card>
      </div>
    </div>


<!-- Enquire popup -->
     <vs-popup class="holamundo" title="Enquire" :active.sync="popupEnquire">
       <div class="p-3">
      <div class="vx-row mb-5">
    <div class="vx-col w-full">
      <vs-input class="w-full" label="Course Name" v-model="course_name" />
    </div>
  </div>
  <div class="vx-row mb-5">
    <div class="vx-col w-full">
      <label class="text-sm">Category</label>
       <v-select :options="['Category 01','Category 02']" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
    </div>
  </div>
  <div class="vx-row mb-5">
    <div class="vx-col w-full sm:w-1/2">
      <vs-input class="w-full" label="Study Area" v-model="study_area" />
    </div>
    <div class="vx-col w-full sm:w-1/2">
      <vs-input class="w-full" label="Course Type" v-model="course_type" />
    </div>
  </div>
  <div class="vx-row mb-5">
    <div class="vx-col w-full sm:w-1/2">
      <vs-input class="w-full" label="University" v-model="university" />
    </div>
    <div class="vx-col w-full sm:w-1/2">
      <vs-input class="w-full" label="Location" v-model="location" />
    </div>
  </div>
  <div class="vx-row mb-5">
    <div class="vx-col w-full">
      <label class="text-sm">Question</label>
      <vs-textarea v-model="textarea" />
    </div>
  </div>

  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-button class="mr-3 mb-2">Submit</vs-button>
      <vs-button color="primary" type="border" class="mb-2" @click="input9 = input10 = input11 = input12 = ''; check3 = false;">Reset</vs-button>
    </div>
  </div>
       </div>
    </vs-popup>

  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import VxTimeline from "../../components/timeline/VxTimeline";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import axios from 'axios'

export default {
  data() {
    return {
      popupEnquire: false,
      CourseData: "",
      Career: "",
      Majors: "",
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }
      }
    };
  },
  components: {
    swiper,
    swiperSlide,
    vSelect,
    flatPickr,
    // VxTimeline,
    StatisticsCardLine
  },
  created() {
    this.getCourseDetailsByID();
    this.getUserDetailsBasedOnUserName();
  },
  mounted () {
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
  },
  beforeDestroy () {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
  },
  computed: {},
  methods: {
    OpenNewTab(url) {
      window.open(url, "_blank");
    },
    async getCourseDetailsByID() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("course_id", localStorage.getItem("pCourseId"));
      urlencoded.append(
        "pub_userid",
        JSON.parse(localStorage.getItem("pCoursepalUserObj"))
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/CourseDetailsBasedOnStudent",
        requestOptions
      );

      const response = await rawResponse.json();
      this.CourseData = response.data[0];
      this.Career =
        response.data[0].career_names == null ||
        response.data[0].career_names == ""
          ? null
          : response.data[0].career_names.toString().split(",");
      this.Majors =
        response.data[0].major_names == null ||
        response.data[0].major_names == ""
          ? null
          : response.data[0].major_names.toString().split(",");

      console.log(this.CourseData);
    },
    async AddUserEnquiryForCourse() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("course_id", localStorage.getItem("pCourseId"));
      urlencoded.append(
        "pub_userid",
        JSON.parse(localStorage.getItem("pCoursepalUserObj")).pub_userid
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/AddUserEnquiryForCourse",
        requestOptions
      );

      const response = await rawResponse.json();

      this.$vs.notify({
        title: "Enquiry Add Successfully",
        text: response.data[0].message,
        color: "success",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-alert-circle"
      });
      this.$router.push("/enquired");

      //  }
    },
    async AddUserShortlistCourse() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("course_id", localStorage.getItem("pCourseId"));
      urlencoded.append(
        "pub_userid",
        this.pub_userid
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/AddUserShortlistCourse",
        requestOptions
      );

      const response = await rawResponse.json();

      this.$vs.notify({
        title: "ShortListed Successfully",
        text: response.data[0].message,
        color: "success",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-alert-circle"
      });
      // this.$router.push("/bookmarks");

      //  }
    },
    async redirectMyApplication() {
      this.$router.push({ name: 'my-application'})
    },
    async AddUserApplyForCourse() {
      var myHeaders = "";
      var urlencoded = "";
      var requestOptions = "";
      myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      urlencoded = new URLSearchParams();
      urlencoded.append(
        "pub_userid",
        JSON.parse(localStorage.getItem("pCoursepalUserObj")).pub_userid
      );

      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const rawResponse = await fetch(
        "https://api.coursepal.app/api/ProfileStatus",
        requestOptions
      );

      const response = await rawResponse.json();

      console.log(Math.round(response.data[0].filled_percentage));

      if (Math.round(response.data[0].filled_percentage) > 85) {
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        urlencoded = new URLSearchParams();
        urlencoded.append("course_id", localStorage.getItem("pCourseId"));
        urlencoded.append(
          "pub_userid",
          JSON.parse(localStorage.getItem("pCoursepalUserObj")).pub_userid
        );

        requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };

        const rawResponse = await fetch(
          "https://api.coursepal.app/AddUserApplyForCourse",
          requestOptions
        );

        const response = await rawResponse.json();

        this.$vs.notify({
          title: "Applied Successfully",
          text: response.data[0].message,
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        // this.$router.push("/applied");
      } else {
        this.openConfirm();
      }
    },
    openConfirm() {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: `Confirm`,
        text: "Please complete your profile first to apply for this course",
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      this.$router.push("/my-application");
    },
    goToChatCoursepal () {
      this.$router.push(`/chat/Coursepal-${this.$store.state.auth.userData.name}`)
    },

    getUserDetailsBasedOnUserName(){     
      
      // this.$vs.loading();
      axios.post(`${process.env.VUE_APP_BASE_URL  }/api/users/getUserDetailsBasedOnUserName`, {
          user_name:  this.$store.state.auth.userData.name
        }).then(res => {

          if (res.data.data[0] !== undefined) {
            this.pub_userid = res.data.data[0].pub_userid;
          }
      });
    }
  }
};
</script>
<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 160px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
    a {
      padding-left: 10px;
    }
  }
  .timeline {
    .vx-timeline {
      li {
        margin-bottom: 7px;
      }
    }
  }
  .truncate {
    span {
      white-space: initial;
    }
  }
}
#address {
  .adjust {
    padding-top: 10px;
  }
}



// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }
}
</style>
